
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "File",

  setup() {
    const store = useStore();
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "transport_order_status",
        route: "/transport_order_status",
        icon: "bi bi-activity",
        count: "",
        permission: "transport order status: index",
      },
      {
        name: "transport_order_type",
        route: "/transport_order_type",
        icon: "bi bi-truck",
        count: "",
        permission: "transport order type: index",
      },
      {
        name: "maritime_file_type",
        route: "/maritime_file_type",
        icon: "bi bi-archive",
        count: "",
        permission: "maritime file type: index",
      },
      {
        name: "routes_type",
        route: "/routes_type",
        icon: "bi bi-fullscreen-exit",
        count: "",
        permission: "routes type: index",
      },
      {
        name: "maritime_file_operations",
        route: "/maritime_file_operations",
        icon: "bi bi-calculator",
        count: "",
        permission: "maritime file operations: index",
      },
      {
        name: "smpurposes",
        route: "/smpurposes",
        icon: "bi bi-card-list",
        count: "",
        permission: "smpurposes: index",
      },
      {
        name: "smrequirements",
        route: "/smrequirements",
        icon: "bi bi-card-checklist",
        count: "",
        permission: "smrequirements: index",
      },
      {
        name: "smoperation_type",
        route: "/smoperation_type",
        icon: "bi bi-collection",
        count: "",
        permission: "smoperation type: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === item.permission).length > 0
        );
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
        .then(({ data }) => {
          permissions.value = data;
        })
        .catch((error) => {
          console.log(error);
        });
      setCurrentPageBreadcrumbs("ifile", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/files").then(({ data }) => {
        dataObject.value.forEach((item, index) => {
          item.count = data[index];
        });
        store.commit("setLoadingStatus", false);
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
});
